<!--
 * @Description: 巡检单
 * @Author: ChenXueLin
 * @Date: 2021-10-12 14:02:56
 * @LastEditTime: 2023-02-20 16:18:00
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 基本信息 start -->
          <template>
            <div class="primaryTitle">巡检任务单</div>
            <div class="edit-title">基本信息</div>
            <div class="edit-content">
              <el-form
                ref="baseInfoForm"
                class="form-content"
                label-position="right"
                :inline="true"
                label-width="120px"
                :rules="baseInfoFormRules"
                :model="baseInfoForm"
              >
                <el-form-item label="客户名称" prop="corpId">
                  <e6-vr-select
                    v-model="baseInfoForm.corpId"
                    :data="corpList"
                    placeholder="客户名称"
                    title="客户名称"
                    clearable
                    virtual
                    remote
                    :is-title="true"
                    @filterChange="handleLoadCorpFilter"
                    @change="handleChange"
                    :props="{
                      id: 'corpId',
                      label: 'corpName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="要求完成时间" prop="expecttime">
                  <el-date-picker
                    v-model="baseInfoForm.expecttime"
                    type="datetime"
                    placeholder="选择日期"
                    value-format="timestamp"
                    popper-class="special"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                  <el-input
                    v-model="baseInfoForm.remark"
                    placeholder="请输入备注"
                    type="textarea"
                    :rows="3"
                    style="width: 500px"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </template>
          <!-- 基本信息 end -->
          <!-- 巡检点   start -->
          <template>
            <div class="edit-title">巡检点</div>
            <div class="edit-content" style="margin-bottom: 20px">
              <div class="associated-info">
                <div class="right-button">
                  <el-button type="primary" @click="handleAdd">
                    添加巡检点
                  </el-button>
                  <el-button type="primary" @click="deleteAddress">
                    删除巡检点
                  </el-button>
                  <!-- <el-button type="primary">
                    导入巡检点
                  </el-button> -->
                </div>
              </div>
              <el-table
                :data="inspectionData"
                highlight-current-row
                @selection-change="handleSelection"
              >
                <el-table-column
                  type="selection"
                  width="50"
                  align="center"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  v-for="(column, index) in inspectionColumn"
                  :key="index"
                  :prop="column.fieldName"
                  :label="column.fieldLabel"
                  :min-width="column.width"
                  :fixed="column.fixed"
                  :align="column.align"
                  header-align="center"
                >
                </el-table-column>
              </el-table>
            </div>
          </template>
          <!-- 巡检点   end -->
          <!-- 任务项设置   start -->
          <template>
            <div class="edit-title">任务项设置</div>
            <div class="edit-content" style="margin-bottom: 20px">
              <div class="associated-info">
                <div class="right-button">
                  <el-button type="primary" @click="addScene">
                    添加任务项
                  </el-button>
                  <el-button type="primary" @click="handleDelete">
                    删除任务项
                  </el-button>
                  <!-- <el-button type="primary">
                    导入任务项
                  </el-button> -->
                </div>
              </div>
              <el-table
                ref="multipleTable"
                :data="tableData"
                highlight-current-row
                height="300px"
                @selection-change="handleSelectionChange"
              >
                <el-table-column
                  type="selection"
                  width="50"
                  align="center"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="sceneName"
                  label="场景名称"
                  align="center"
                  header-align="center"
                  fixed
                >
                  <template slot-scope="{ row }">
                    <span v-if="row.show">{{ row.sceneName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="secondClassName"
                  label="安装组合"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="{ row }">
                    <span v-if="row.show2">{{ row.secondClassName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="thirdClassName"
                  label="已安装商品"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="firstClassName"
                  label="商品分类"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="equipCode"
                  label="设备编号"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="virtualTypeName"
                  label="类型"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
              </el-table>
            </div>
          </template>
          <!-- 任务项设置   end -->
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </div>
      </section>
      <!-- 添加巡检点 -->
      <el-dialog
        v-dialogDrag
        title="添加巡检点"
        :visible="addDialog"
        width="600px"
        :close-on-click-modal="false"
        :element-loading-background="loadingBackground"
        :before-close="handleCloseDialog"
        custom-class="dialog-box"
      >
        <el-form
          ref="addForm"
          :model="addForm"
          label-width="120px"
          :rules="addFormRules"
        >
          <el-form-item prop="contactId" label="联系人">
            <div class="select-content">
              <e6-vr-select
                v-model="addForm.contactId"
                :data="contactList"
                placeholder="联系人"
                title=""
                :props="{
                  id: 'contactId',
                  label: 'contactName'
                }"
                clearable
                @change="handleContact"
                @clear="handleClear"
              ></e6-vr-select>
              <el-button
                type="text"
                style="margin-left: 10px"
                @click="addConcat"
              >
                添加联系人
              </el-button>
            </div>
          </el-form-item>
          <el-form-item
            class="search-item--1"
            prop="contactPhone"
            label="联系电话"
          >
            <el-input
              v-model="addForm.contactPhone"
              placeholder="联系电话"
              title="联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="省市区地址" prop="defaultCity">
            <e6-vr-select
              style="width:300px"
              v-model="addForm.defaultCity"
              :data="addressList"
              placeholder="联系地址"
              title="联系地址"
              virtual
              clearable
              :is-title="true"
              remote
              @filterChange="handleLoadAddressFilter"
              @change="handleCitySelect"
              :props="{
                id: 'fullName',
                label: 'fullName'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item label="详细地址" prop="insAddress">
            <div class="address-box">
              <el-input
                placeholder="详细地址"
                v-model="addForm.insAddress"
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
        <div class="dialog-footer" slot="footer">
          <el-button class="cancel" @click="handleCloseDialog">取消</el-button>
          <el-button type="primary" @click="addSubmit">确定</el-button>
        </div>
      </el-dialog>
      <!-- 选择场景 -->
      <select-scene
        :selectSceneDialog="selectSceneDialog"
        @handleClose="handleClose"
        @getData="getData"
        :corpId="baseInfoForm.corpId"
      ></select-scene>
      <!-- 添加联系人弹框 -->
      <add-concat-dialog
        :addContactVisible="addContactVisible"
        @handleClose="handleClose"
        @updateList="updateList"
        :corpName="baseInfoForm.corpName"
        :corpId="baseInfoForm.corpId"
        :isNeedReq="true"
      ></add-concat-dialog>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import selectScene from "@/components/workOrderManage/selectScene.vue";
import { createInspection } from "@/api";
import { printError } from "@/utils/util";
import { telAndMobileValid } from "@/utils/validate";
import addTask from "@/mixins/addTask";
import AddConcatDialog from "@/components/addConcatDialog.vue";
export default {
  name: "addInspectionOrder",
  components: {
    selectScene,
    AddConcatDialog
  },
  data() {
    return {
      loading: false,
      isConfirm: 1,
      workSecondClass: "11",
      nowDate: new Date().setHours(new Date().getHours() + 4),
      baseInfoForm: {
        corpId: "", //公司id
        insAddress: "", //详细地址
        linkmanrpid: "", //联系人id
        contactPhone: "", //电话
        remark: "",
        defaultCity: "", //默认选中地址
        expecttime: new Date().setHours(new Date().getHours() + 4)
      }, //基本信息
      baseInfoFormRules: {
        corpId: [
          {
            required: true,
            message: "请选择客户名称",
            trigger: ["blur", "change"]
          }
        ],
        expecttime: [
          {
            required: true,
            message: "请选择解决时间",
            trigger: ["blur", "change"]
          }
        ],
        defaultCity: [
          {
            required: true,
            message: "请选择地址",
            trigger: ["blur", "change"]
          }
        ]
      },
      //巡检点表头
      inspectionColumn: [
        {
          fieldName: "detailAddress",
          display: true,
          fieldLabel: "巡检点地址",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "contactName",
          display: true,
          fieldLabel: "联系人",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "contactPhone",
          display: true,
          fieldLabel: "联系电话",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      inspectionData: [],
      //任务项设置表头
      columnData: [
        {
          fieldName: "id",
          display: true,
          fieldLabel: "场景名称",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "name",
          display: true,
          fieldLabel: "场景类型",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "type",
          display: true,
          fieldLabel: "安装组合",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "size",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "count",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "count",
          display: true,
          fieldLabel: "商品类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "desc",
          display: true,
          fieldLabel: "设备编号",
          width: 350,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      tableData: [],
      addressList: [],
      //选中的巡检点
      selectAddress: [],
      /** 添加巡检点*/
      addForm: {
        contactId: "", //联系人
        contactName: "",
        contactPhone: "", //电话
        insAddress: "", //详细地址
        province: "", // 省份id
        provinceName: "", // 省份名称
        city: "", // 城市id
        cityName: "", // 城市名称
        insCounty: "", // 区县id
        insCountyName: "", // 区县name
        street: "",
        detailAddress: "", //地址拼接
        defaultCity: ""
      },
      addFormRules: {
        contactId: [
          {
            required: true,
            message: "请选择联系人姓名",
            trigger: ["blur", "change"]
          }
        ],
        contactPhone: telAndMobileValid.required({
          requiredMsg: "请输入电话号码",
          message: "号码格式不正确",
          trigger: ["blur", "change"]
        }),
        defaultCity: [
          {
            required: true,
            message: "请选择省市区",
            trigger: ["blur", "change"]
          }
        ],
        insAddress: {
          required: true,
          message: "请填写详细地址",
          trigger: ["blur", "change"]
        }
      },
      // 选择场景
      addContactVisible: false,
      selectSceneDialog: false,
      addDialog: false //添加巡检点
    };
  },
  mixins: [base, addTask],
  watch: {
    // 监听联系人名称更改
    "addForm.contactId": {
      immediate: true,
      handler(val) {
        if (val !== "") {
          let concatInfo = this.contactList.filter(item => {
            return item.contactId == val;
          })[0];
          if (concatInfo.phone1) {
            this.addForm.contactPhone = concatInfo.phone1;
          }
        } else {
          this.addForm.contactPhone = "";
        }
      }
    }
  },
  methods: {
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.baseInfoForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    /********* 添加巡检点  start  *********/
    handleAdd() {
      if (!this.baseInfoForm.corpId) {
        this.$message.warning("请先选择客户名称");
        return;
      }
      this.addDialog = true;
    },
    //多选数据
    handleSelection(val) {
      this.selectAddress = val;
    },
    //联系人选择
    handleContact() {
      this.handleClear();
      let concatInfo = this.contactList.find(item => {
        return item.contactId == this.addForm.contactId;
      });
      this.addForm.contactPhone = concatInfo.phone1 ? concatInfo.phone1 : "";
      if (concatInfo.addressList.length && concatInfo.addressList[0].areaName) {
        //省
        this.addForm.provinceName = concatInfo.addressList[0].provinceName;
        this.addForm.province = concatInfo.addressList[0].provinceId;
        //市
        this.addForm.cityName = concatInfo.addressList[0].cityName;
        this.addForm.city = concatInfo.addressList[0].cityId;
        //区
        this.addForm.insCountyName = concatInfo.addressList[0].areaName;
        this.addForm.insCounty = concatInfo.addressList[0].areaId;
        //街道
        this.addForm.streetName = concatInfo.addressList[0].streetName;
        this.addForm.street = concatInfo.addressList[0].streetId;
        //详细地址
        this.addForm.insAddress = concatInfo.addressList[0].address;
        //默认地址
        this.addForm.defaultCity =
          concatInfo.addressList[0].provinceName +
          concatInfo.addressList[0].cityName +
          concatInfo.addressList[0].areaName +
          concatInfo.addressList[0].streetName;
        this.loadAddressData(this.addForm.defaultCity);
      }
    },
    //清空联系人
    handleClear() {
      this.addForm.contactPhone = "";
      this.addForm.provinceName = "";
      this.addForm.province = "";
      //市
      this.addForm.cityName = "";
      this.addForm.city = "";
      //区
      this.addForm.insCountyName = "";
      this.addForm.insCounty = "";
      //街道
      this.addForm.streetName = "";
      this.addForm.street = "";
      //详细地址
      this.addForm.insAddress = "";
      this.addForm.defaultCity = "";
    },
    // 处理城市选择
    handleCitySelect(select, node) {
      let val = _.cloneDeep(node);
      this.addForm.province = val.provinceCode;
      this.addForm.provinceName = val.provinceName;
      this.addForm.city = val.cityCode;
      this.addForm.cityName = val.cityName;
      this.addForm.insCounty = val.countyCode;
      this.addForm.insCountyName = val.countyName;
      this.addForm.streetId = val.streetCode;
      this.addForm.street = val.streetCode;
      this.addForm.streetName = val.streetName;
      this.addForm.defaultCity = val.fullName;
    },
    handleLoadAddressFilter: _.debounce(async function(val) {
      if (val) {
        this.addForm.defaultCity = "";
        this.addForm.province = "";
        this.addForm.provinceName = "";
        this.addForm.city = "";
        this.addForm.cityName = "";
        this.addForm.insCounty = "";
        this.addForm.insCountyName = "";
        this.addForm.streetId = "";
        this.addForm.street = "";
        this.addForm.streetName = "";
        this.loadAddressData(val);
      }
    }, 300),
    //点击添加确定
    addSubmit() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          // if (!this.addForm.insCountyName) {
          //   this.$message.warning("区县不能为空");
          //   return;
          // }
          this.addForm.detailAddress =
            this.addForm.defaultCity + this.addForm.insAddress;
          this.addForm.contactName = this.contactList.find(item => {
            return item.contactId == this.addForm.contactId;
          }).contactName;
          let inspectionData = _.cloneDeep(this.inspectionData);
          let filterArr = inspectionData.filter(item => {
            return (
              item.detailAddress == this.addForm.detailAddress &&
              item.contactId == this.addForm.contactId &&
              item.contactPhone == this.addForm.contactPhone
            );
          });

          if (filterArr.length) {
            this.$message.warning("已存在此数据");
            return;
          } else {
            // this.addForm.id = inspectionData.length + 2;
            inspectionData.push(_.cloneDeep(this.addForm));
            this.inspectionData = inspectionData;
            this.handleCloseDialog();
          }
        }
      });
    },
    //关闭添加
    handleCloseDialog() {
      this.addForm = {
        province: "", // 省份id
        provinceName: "", // 省份名称
        city: "", // 城市id
        cityName: "", // 城市名称
        insCounty: "", // 区县id
        insCountyName: "", // 区县name
        defaultCity: "",
        insAddress: "", // 详细地址
        contactId: "", // 收货人名字
        contactName: "",
        contactPhone: "" //联系人电话
      };
      this.$refs["addForm"].resetFields();
      this.addDialog = false;
    },
    //删除巡检点
    deleteAddress() {
      let inspectionData = _.cloneDeep(this.inspectionData);
      let selectAddress = _.cloneDeep(this.selectAddress);
      this.inspectionData = inspectionData.filter(v =>
        selectAddress.every(n => {
          return !(
            n.detailAddress == v.detailAddress &&
            n.contactId == v.contactId &&
            n.contactPhone == v.contactPhone
          );
        })
      );
    },
    /********* 添加巡检点  end  *********/
    //点击确定新增
    submit() {
      this.$refs.baseInfoForm.validate(valid => {
        if (valid) {
          if (this.baseInfoForm.expecttime < this.nowDate) {
            this.$message.warning("要求完成时间不能小于当前时间+4小时");
            return;
          }
          if (!this.tableData.length) {
            this.$message.warning("至少有一个任务项");
            return;
          }
          if (!this.inspectionData.length) {
            this.$message.warning("至少有一个巡检点");
            return;
          }
          this.checkCreateTaskReq();
        }
      });
    },

    //创建巡检单请求
    async createTaskReq() {
      try {
        this.loading = true;
        let res = await createInspection({
          ...this.baseInfoForm,
          isConfirm: this.isConfirm,
          itemInspectionReqVOList: this.tableData,
          taskInspectionSpotReqVOList: this.inspectionData
        });
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.closeTag(this.$route);
          this.routerPush({
            name: "taskListManage/allTaskList",
            params: {
              refresh: true
            }
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },

    //添加联系人之后更新下拉框
    updateList() {
      this.getContactList();
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-content {
  padding: 20px 20px 2px;
  .e6-vr-select {
    width: 100%;
  }
  .el-table {
    & tr th > .cell {
      height: 38px;
      line-height: 38px;
      color: #898c91;
      background-color: #ffffff;
    }
    .el-table__body .el-table__row:nth-child(2n) td {
      background-color: #ffffff;
    }
  }
}

.form-content {
  .el-input {
    width: 280px;
  }
}

.edit-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  .operate-action {
    height: 38px;
  }
}

.associated-info {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.table-input {
  width: 300px;
}
/deep/.dialog-box {
  .el-dialog__body {
    padding: 20px;
    box-sizing: border-box;
    .el-input {
      width: 220px;
    }
  }
}
/deep/.edit-address {
  .el-form-item__label {
    &::before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
}
</style>
